import { API_URL } from 'config.js';
import { get, post } from './helpers';

export const getroles = async () => {
    const response = await get(`${API_URL}/roles`);
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const getPaymentsRoles = async () => {
    const response = await get(`${API_URL}/payment-references/roles`);
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const addPaymentsRoles = async (payload) => {
    const response = await post(`${API_URL}/payment-references/roles`, payload);
    return {
        status: response?.status,
        ...(await response.json()),
    };
};
