import { API_URL } from 'config.js';
import { get, upload, post } from './helpers';
import { Token } from '@mui/icons-material';

export const getSettings = async (id) => {
    const response = await get(`${API_URL}/settings/${id}`);
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const get_phone_codes = async () => {
    const response = await get(`${API_URL}/phone-code`);
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const quoteGetSettings = async (id) => {
    const response = await get(`${API_URL}/settings/get-settings/${id}`);
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const get_integration_rules = async (id) => {
    const response = await get(`${API_URL}/get-integration-rules`);
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const uploadLogo = async (payload) => {
    const response = await upload(`${API_URL}/uploadLogo`, payload);
    return {
        status: response.status,
        ...(await response.json()),
    };
};

export const generate_locker_auth_key = async (payload = {}) => {
    const response = await post(`${API_URL}/generate-locker-auth-key`, payload);
    return {
        status: response.ok,
        ...(await response.json()),
    };
};

export const add_integrations = async (payload = {}) => {
    const response = await post(`${API_URL}/integrations`, payload);
    return {
        ok: response.ok,
        ...(await response.json()),
    };
};

export const generate_bearer_token = async (payload = {}) => {
    const response = await post(`${API_URL}/generate-bearer-token`, payload);
    return {
        ok: response.ok,
        Token: response.ok ? await response.text() : null
    };
};

export const resendWebhook = async (payload) => {
    const response = await post(`${API_URL}/resend-webhook`, payload);
    return {
        status: response.status,
        ...(await response.json()),
    };
};