import { API_URL } from 'config.js';
import { get, upload, put, post, patch } from './helpers';

export const getNetworks = async () => {
    const response = await get(`${API_URL}/networks`);
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const getLockers = async () => {
    const response = await get(`${API_URL}/lockers`);
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const getAccessTypes = async () => {
    const response = await get(`${API_URL}/access-types`);
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const getLockerTypes = async () => {
    const response = await get(`${API_URL}/lockers-types`);
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const duplicateDoors = async (payload) => {
    const response = await post(`${API_URL}/doors`, payload);
    return {
        status: response.status,
        ...(await response.json()),
    };
};

export const createLocker = async (payload) => {
    const response = await post(`${API_URL}/lockers`, payload);
    return {
        response: response.status,
        ...(await response.json()),
    };
};

export const udpateLocker = async (payload, id) => {
    const response = await put(`${API_URL}/lockers/${id}`, payload);
    return {
        response: response.status,
        ...(await response.json()),
    };
};

export const getBoxesParcelsData = async (code) => {
    const response = await get(`${API_URL}/lockers-view/${code}`);
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const getLockersParcelsData = async (code) => {
    const response = await get(`${API_URL}/lockers-shipment/${code}`);
    if (response?.status !== 200) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const addDoor = async (payload) => {
    const response = await post(`${API_URL}/add-door`, payload);
    return {
        status: response.status,
        ...(await response.json()),
    };
};

export const getDoorSizes = async () => {
    const response = await get(`${API_URL}/get-door-sizes`);
    if (response.status !== 200) return false;
    const data = await response.json();
    return {
        sizes: data
    };
};
